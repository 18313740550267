<template>
    <div>

        <v-form ref="registerform">
            <v-stepper v-model="step" style="margin-top:-6px">
        <search-area  :elevate=0 height="0">
            <template v-slot:item>
                <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1" :editable="step ===1"
                    >
                        Account info
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 2" :editable="step ===2"
                                    step="2"
                    >Location</v-stepper-step>
                </v-stepper-header>
            </template>
        </search-area>

<!--            <v-divider></v-divider>-->
            <v-stepper-items >

                    <v-stepper-content step="1" >
                        <v-form ref="step_one">
                        <v-autocomplete
                                v-model="form.usertype"
                                ref='usertype'
                                :items="['Agent','Developer']"
                                item-value="id"
                                item-text="name"
                                cache-items
                                :rules="[rules.required]"
                                placeholder="eg Agent"

                                label="I am "
                                clearable
                                outlined
                        ></v-autocomplete>
                        <v-text-field ref="company" outlined label="Company/Business Name" type="text" :rules="[rules.required]" v-model="form.company_name"></v-text-field>
                         <v-text-field ref="firstname" outlined label="First Name" type="text" :rules="[rules.required]" v-model="form.firstname"></v-text-field>
                        <v-text-field ref="lastname" outlined label="Last Name" type="text" :rules="[rules.required]" v-model="form.lastname"></v-text-field>
                        <v-text-field ref="email" outlined label="Email" type="text" :rules="[rules.email,rules.required]" v-model="form.email"></v-text-field>
<!--                        <v-text-field ref="phone" outlined label="Phone Number" type="text" :rules="[rules.required,rules.phone,rules.phone_length]" v-model="form.phone"></v-text-field>-->
                        <vue-tel-input-vuetify outlined v-model="form.phone" :rules="[rules.required,rules.phone,rules.phone_length]"></vue-tel-input-vuetify>

                        </v-form>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                          <v-form ref="step_two">
                        <v-autocomplete
                                v-model="form.state_id"
                                :items="states"
                                :search-input.sync="search"
                                item-value="id"
                                item-text="name"
                                cache-items
                                :rules="[rules.required]"
                                placeholder="eg Lagos"

                                label="State"
                                clearable
                                outlined
                        ></v-autocomplete>
                        <v-text-field ref="city" outlined label="City" placeholder="Ikeja" type="text" :rules="[rules.required]" v-model="form.city"></v-text-field>
                        <v-textarea :rules="[rules.required]" v-model="form.address" auto-grow height="60" placeholder="20, okoro street"
                                outlined
                                label="Address" clearable
                        ></v-textarea>
                        <v-text-field
                                outlined
                                ref="password"
                                label="Password"
                                v-model="form.password"
                                name="password"
                                :append-icon="show_pass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                @click:append="() => (show_pass = !show_pass)"
                                :type="show_pass ? 'text' : 'password'"
                                :rules="[rules.required,rules.pass_counter]"
                        />
                           <v-text-field
                                outlined
                                ref="password"
                                label="Confirm Password"
                                v-model="confirm_password"
                                name="password"
                                type="password"
                                :rules="[rules.required,passwordConfirmationRule]"
                        />

                        <v-checkbox v-model="isAgree" :rules="[rules.required]">
                            <template v-slot:label>
                                <div>
                                    By checking this box, you agree to   <a
                                        href="#"
                                        @click="openPolicyModal()"
                                >
                                    Rems
                                </a>
                                    terms and conditions
                                </div>
                            </template>
                        </v-checkbox>
                          </v-form>

                    </v-stepper-content>


            </v-stepper-items>
        </v-stepper>
            <v-bottom-navigation app :fixed=true  style="justify-content: space-evenly;padding-left:10px">
                <div style="height: 40px; width:50% ;padding:8px;">
                    <v-btn width="150"  @click="step--" v-if="step !== 1" class="offer_btn" large dark style=" border-radius: 5px;color: #379392 !important;background-color: #eff6f5 !important;">Back</v-btn>
                </div>
                <div style="height: 40px; width:50% ;padding:8px;">
                    <v-btn width="150"  :loading="apiloading"
                           :disabled="apiloading"
                           color="secondary" @click.prevent="goNextPage()" class="inspection_btn" style=" border-radius: 5px;color: #fff !important;"> {{step===2?'Submit':'Continue'}}</v-btn>
                </div>
            </v-bottom-navigation>
        </v-form>

<!--        policy modal-->
        <div class="text-center">
            <v-dialog scrollable
                    v-model="policy_modal"
                    width="500"
            >

                <v-card >
                    <v-card-title
                            class="secondary text-center"
                            color="secondary"
                    >
                        <span style="color:#fff" class="text-center">REMS POLICY</span>
                    </v-card-title>

                    <v-card-text style="height: 300px">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="policy_modal = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
import { loadingMixin } from '../../mixins/Loading';
export default {
    inject:["Bus"],
    mixins:[loadingMixin],
    data(){
        return{
            step:1,
             show_pass: false,
            search:'',
            countryCode: '+234',
            isAgree:'',
            policy_modal:false,
            confirm_password:'',
            form:{
                company_name:'',
                firstname:'',
                lastname:'',
                email:'',
                phone:'',
                city:'',
                password:'',
                state_id:'',
                address:'',
                usertype:"",
            },
            first_step:['usertype','company','firstname','lastname','email','phone'],
             rules: {
                required: value => !!value || "Required.",
                counter: value => value.length < 20 || "Max 20 characters",
                 pass_counter: value => value.length >=6 || "Minimum 6 characters required",
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail address.";
                },
                phone:value=>{
                    const regre = /[0-9]|\./
                     return regre.test(value) || "Invalid phone number";
                 },
                 phone_length:value => value.length >= 10 || "incorrect phone number"
                },
        }
    },
    computed:{
        states(){
            return this.$store.state.general_module.all_states;
        },
        passwordConfirmationRule() {
      return () => (this.form.password === this.confirm_password) || 'Password must match'
    },
    },
    methods:{
        countrySelected(val) {
            this.countryCode = val.dialCode;
        },
        openPolicyModal(){
            this.policy_modal = true;
        },
        register(){
            if(!this.$refs.registerform.validate()){
                // this.step = 1;
                    return
            }
            this.$store.dispatch("register",this.form);
        },
        goNextPage(){
           switch(this.step){
               case 1:
                   if(!this.$refs.step_one.validate()){
                       return;
                   }
                   this.step++
                window.scrollTo(0,0);
                break;
                case 2:
                if(!this.$refs.step_two.validate()){
                       return;
                   }
                   this.register();

           }


        //   this.first_step.map((a)=>{
        //     //   console.log('f',a)
        //         if(!this.$refs.a.value == ''){
        //             return;
        //             console.log('f',"err "+a)
        //         }
        //         // this.step++
        //         // window.scrollTo(0,0)
        //     })


        },
        isValid(arr){
            let status = false;
            arr.map((a)=>{
                if(this.$refs.a !=='' || this.$refs.a !==null){
                    this.status = true;
                }
            })
            return status;

        }
    },
    created(){
        this.Bus.$emit("title-change",'Register');
    }
}
</script>
<style scoped>
    .v-stepper{
        border-radius: 0 !important;
        box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
        -webkit-box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    .v-stepper__header {
        box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
        -webkit-box-shadow:0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    .step_button{
        display: flex;
        justify-content: space-between;
    }
</style>